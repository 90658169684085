import React from "react";
import "./about.css";
import About1 from "../../assets/About1.jpg";
import About2 from "../../assets/About2.jpg";
import About3 from "../../assets/About3.jpg";
import About4 from "../../assets/About4.jpg";
import About5 from "../../assets/About5.jpg";
import About6 from "../../assets/About6.jpg";
import About7 from "../../assets/About7.jpg";
import About8 from "../../assets/About8.jpg";
import About9 from "../../assets/About9.jpg";
import About10 from "../../assets/About10.webp";

const About = () => {
    let pressed = false;
    let startX = 0;
    let arrowColor = "#e1e1e1e1";

    const grabbing = (e) => {
        const wrapper = document.querySelector(".wrapper");
        pressed = true;
        startX = e.clientX;
        wrapper.style.cursor = "grabbing";
    };

    const leave = (e) => {
        pressed = false;
    };

    const grab = (e) => {
        const wrapper = document.querySelector(".wrapper");
        pressed = false;
        wrapper.style.cursor = "grab";
    };

    const move = (e) => {
        const wrapper = document.querySelector(".wrapper");
        if (!pressed) {
            return;
        }
        wrapper.scrollLeft += startX - e.clientX;
    };

    return (
        <>
            <section
                className='about section hidden md:block max-w-[1200px] m-auto'
                id='about'
            >
                <div className='flex flex-row justify-center gap-5'>
                    <div className='flex w-[100%] gap-2'>
                        <div className='flex flex-col justify-center gap-5'>
                            <div className='flex flex-col text-black '>
                                <h1 className=' text-[2.5rem] font-black text-black '>
                                    About our town
                                </h1>
                                <p className='text-[1rem] '>
                                    Tequila it’s quaint town nestled in Jalisco,
                                    built with cobblestone streets and the aroma
                                    of{" "}
                                    <span className='font-black'>
                                        agave azul
                                    </span>
                                    , a place where history and tradition
                                    harmonize in every adobe wall. Its essence,
                                    distilled in the warmth of hospitality,
                                    beckons travelers to savor the rich heritage
                                    woven into its vibrant tapestry.
                                </p>
                            </div>
                            <div className='flex flex-col text-black '>
                                <h1 className='text-[1.75rem] font-black text-black '>
                                    What to do?
                                </h1>
                                <p className='text-[1rem] '>
                                    <span className='  font-black'>
                                        Learn the artesanal process of our
                                        heritage
                                    </span>{" "}
                                    <br />
                                    See first hand how the Agave turns to
                                    Tequila through multiple steps of handmade
                                    work learned trough generations.
                                </p>
                            </div>
                            <div className='flex flex-col text-black '>
                                <h1 className='text-[1rem]  font-black text-black '>
                                    Visit the heart of Tequila
                                </h1>
                                <p className='text-[1rem] '>
                                    Temple of Santiago Apostol stands as a
                                    testament to Mexico's rich cultural
                                    tapestry. This historic gem, with its
                                    stunning colonial architecture and ornate
                                    details is a must visit while in town.
                                </p>
                            </div>
                            <div className='flex flex-col text-black '>
                                <h1 className='text-[1rem]  font-black text-black '>
                                    Try some Tequila
                                </h1>
                                <p className='text-[1rem] '>
                                    Immerse yourself in Mexico´s drink, getting
                                    to know the variety of flavors hidden behind
                                    every color of tequila and in the different
                                    ways it can be served.
                                </p>
                            </div>
                            <div className='flex flex-col text-black '>
                                <h1 className='text-[1rem]  font-black text-black '>
                                    Savor our cuisine
                                </h1>
                                <p className='text-[1rem]'>
                                    Enjoy a morning having breakfast at Cafe
                                    Azulado, have lunch in Fonda Cholula
                                    appreciating the view of the temple and
                                    discover the flavors of town walking around.
                                </p>
                            </div>
                        </div>
                        <div className='flex'></div>
                    </div>

                    <div
                        className='flex wrapper gap-0 '
                        onMouseDown={grabbing}
                        onMouseLeave={leave}
                        onMouseUp={grab}
                        onMouseMove={move}
                    >
                        <svg
                            viewBox='0 0 18 30'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className='z-[99999] md:w-8 w-4 opacity-80 absolute ml-[30rem] mt-[3rem]'
                        >
                            <path
                                d='M1 10L7 16.0645L1 22'
                                stroke={arrowColor}
                                stroke-width='3'
                            />
                            <path
                                d='M4 1L17 15.1505L4 29'
                                stroke={arrowColor}
                                stroke-width='3'
                            />
                        </svg>
                        <div className='slider overflow-y-hidden min-w-[600px] flex justify-center items-center'>
                            <img
                                src={About1}
                                alt='ourspaces1'
                                className='image w-[550px] h-[85vh]'
                            />
                        </div>

                        <div className='slider overflow-y-hidden min-w-[600px] flex justify-center items-center'>
                            <img
                                src={About2}
                                alt='ourspaces2'
                                className='image w-[550px] h-[85vh]'
                            />
                        </div>
                        <div className='slider overflow-y-hidden min-w-[600px] flex justify-center items-center'>
                            <img
                                src={About3}
                                alt='ourspaces3'
                                className='image w-[550px] h-[85vh]'
                            />
                        </div>
                        <div className='slider overflow-y-hidden min-w-[600px] flex justify-center items-center'>
                            <img
                                src={About4}
                                alt='ourspaces4'
                                className='image w-[550px] h-[85vh]'
                            />
                        </div>
                        <div className='slider overflow-y-hidden min-w-[600px] flex justify-center items-center'>
                            <img
                                src={About5}
                                alt='ourspaces5'
                                className='image w-[550px] h-[85vh]'
                            />
                        </div>
                        <div className='slider overflow-y-hidden min-w-[600px] flex justify-center items-center'>
                            <img
                                src={About6}
                                alt='ourspaces6'
                                className='image w-[550px] h-[85vh]'
                            />
                        </div>
                        <div className='slider overflow-y-hidden min-w-[600px] flex justify-center items-center'>
                            <img
                                src={About7}
                                alt='ourspaces7'
                                className='image w-[550px] h-[85vh]'
                            />
                        </div>
                        <div className='slider overflow-y-hidden min-w-[600px] flex justify-center items-center'>
                            <img
                                src={About8}
                                alt='ourspaces8'
                                className='image w-[550px] h-[85vh]'
                            />
                        </div>
                        <div className='slider overflow-y-hidden min-w-[600px] flex justify-center items-center'>
                            <img
                                src={About9}
                                alt='ourspaces9'
                                className='image w-[550px] h-[85vh]'
                            />
                        </div>
                        <div className='slider overflow-y-hidden min-w-[600px] flex justify-center items-center'>
                            <img
                                src={About10}
                                alt='ourspaces10'
                                className='image w-[550px] h-[85vh]'
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className='about section md:hidden mobile' id='about'>
                <div>
                    <h2
                        className=' text-center text-[2rem] font-[900] text-black'
                        id='spaces__title'
                    >
                        About our town
                    </h2>
                    <span
                        className='text-center block text-[1rem] w-[85vw] mr-auto ml-auto'
                        id='spaces__text'
                    >
                        Tequila it’s quaint town nestled in Jalisco, built with
                        cobblestone streets and the aroma of blue agave, a place
                        where history and tradition harmonize in every adobe
                        wall. Its essence, distilled in the warmth of
                        hospitality, beckons travelers to savor the rich
                        heritage woven into its vibrant tapestry.
                    </span>
                    <div className='flex flex-col md:flex-row justify-around w-5/6 h-4/6 mr-auto ml-auto md:gap-5 lg:gap-2 mt-10'>
                        <img
                            src={About1}
                            alt='About1'
                            className='w-[900px] object-cover'
                            id='Agave'
                        />
                    </div>
                </div>
                <div>
                    <h2
                        className=' text-center text-[2rem] font-[900] text-black mt-5'
                        id='spaces__title'
                    >
                        What to do?
                    </h2>
                    <p className='text-left font-[950] text-black w-[90vw] mr-auto ml-auto'>
                        Learn the artesanal process of out heritage
                    </p>
                    <span
                        className=' text-left block text-[1rem] w-[90vw] mr-auto ml-auto'
                        id='spaces__text'
                    >
                        See first hand how the Agave turns to Tequila through
                        multiple steps of handmade work learned trough
                        generations.
                    </span>

                    <div className='flex flex-col md:flex-row justify-around w-5/6 h-4/6 mr-auto ml-auto md:gap-5 lg:gap-2 mt-5'>
                        <img
                            src={About2}
                            alt='About2'
                            className='w-[800px] h-[270px] object-cover'
                            id='Agave2'
                        />
                    </div>
                </div>
                <div className='mt-5'>
                    <p className='text-left font-[950]  text-black w-[90vw] mr-auto ml-auto'>
                        Visit the heart of Tequila
                    </p>
                    <span
                        className=' text-left block text-[1rem] w-[90vw] mr-auto ml-auto'
                        id='spaces__text'
                    >
                        Temple of Santiago Apostol stands as a testament to
                        Mexico's rich cultural tapestry. This historic gem, with
                        its stunning colonial architecture and ornate details is
                        a must visit while in town.
                    </span>

                    <div className='flex flex-col md:flex-row justify-around w-5/6 h-4/6 mr-auto ml-auto md:gap-5 lg:gap-2 mt-5'>
                        <img
                            src={About3}
                            alt='About3'
                            className='w-[800px] h-[270px] object-cover'
                            id='Agave3'
                        />
                    </div>
                </div>
                <div className='mt-5'>
                    <p className='text-left font-[950]  text-black w-[90vw] mr-auto ml-auto'>
                        Try some Tequila
                    </p>
                    <span
                        className=' text-left block text-[1rem] w-[90vw] mr-auto ml-auto'
                        id='spaces__text'
                    >
                        Immerse yourself in Mexico´s drink, getting to know the
                        variety of flavors hidden behind every color of tequila
                        and in the different ways it can be served.
                    </span>

                    <div className='flex flex-col md:flex-row justify-around w-5/6 h-4/6 mr-auto ml-auto md:gap-5 lg:gap-2 mt-5'>
                        <img
                            src={About4}
                            alt='About4'
                            className='w-[800px] h-[270px] object-cover'
                            id='Agave4'
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default About;
