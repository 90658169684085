import React from "react";
import "./footer.css";

const Footer = () => {
    return (
        <footer className='footer md:mt-10'>
            <section className='max-w-[1200px] footer__container flex justify-between m-auto'>
                <div className='text-slate-100 gap-1 flex flex-col'>
                    <p>tel. +52 374 688 0784</p>
                    <p>cel. +52 33 1669 7977</p>
                    <p>recepcion@hotelgloriagave.com</p>
                    <a
                        href='https://www.instagram.com/hotelgloriagave/'
                        target='_blank'
                        rel='noreferrer'
                    >
                        Follow us on Instagram
                    </a>
                    <a
                        href='https://www.facebook.com/profile.php?id=61554643233435&mibextid=LQQJ4d'
                        target='_blank'
                        rel='noreferrer'
                    >
                        Follow us on Facebook
                    </a>
                </div>
                <div className='text-slate-100'>© Gloriagave 2023</div>
            </section>
        </footer>
    );
};

export default Footer;
