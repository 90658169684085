import React from "react";
import "./location.css";

const Location = () => {
    return (
        <section className='about section' id='about'>
            <div id='fade'>
                <span className='text-center block text-[1rem]'>
                    Tequila, Jalisco
                </span>
                <h2 className=' -mt-12 md:-mt-0 text-center text-[3.125rem] font-[900] text-black'>
                    Our Location
                </h2>
                <span className='block text-center mb-8 text-black text-[1.25rem]'>
                    A place to escape to...
                </span>
                <div className='max-w-[350px] md:max-w-[1206px] mr-auto ml-auto'>
                    {/* <img
                        src={Map}
                        alt='Space1'
                        className='w-[1206px] h-[552px]'
                    /> */}
                    <iframe
                        src='https://snazzymaps.com/embed/619193'
                        className='w-[350px] md:w-[1206px] h-[200px] md:h-[552px] mr-auto ml-auto object-cover'
                    ></iframe>
                    <a
                        href='https://www.google.com/maps/dir//Hotel+Gloriagave,+Galeana+225,+La+Mezcalera,+46400+Tequila,+Jal./@20.8789636,-103.9250185,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x842615e7bd12aedd:0x7c34219aa579f58d!2m2!1d-103.8426168!2d20.8789833?hl=es-419&entry=ttu'
                        target='_blank'
                        rel='noreferrer'
                        className='text-[1rem] items-center flex gap-2 mt-5 opacity-70 hover:opacity-90'
                    >
                        Open in maps
                        <svg
                            viewBox='0 0 18 30'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className='w-4 opacity-80'
                        >
                            <path
                                d='M1 10L7 16.0645L1 22'
                                stroke='#505E5D'
                                stroke-width='3'
                            />
                            <path
                                d='M4 1L17 15.1505L4 29'
                                stroke='#505E5D'
                                stroke-width='3'
                            />
                        </svg>
                    </a>
                </div>
            </div>
            <div className='md:w-[1206px] m-auto mt-28 -mb-8 doubts'>
                <h1 className=' text-[50px] font-[900] text-black'>
                    Have any doubts?
                    <span className='text-[30px] font-[400] ml-2'>
                        {" "}
                        Contact us
                    </span>
                </h1>
                <svg
                    viewBox='0 0 18 30'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='w-4 opacity-80 rotate-90 ml-2'
                >
                    <path
                        d='M1 9L7 15.0645L1 21'
                        stroke='#505E5D'
                        stroke-width='2'
                    />
                    <path
                        d='M4 1L17 15.1505L4 29'
                        stroke='#505E5D'
                        stroke-width='2'
                    />
                </svg>
            </div>
        </section>
    );
};

export default Location;
